<template>
  <div class="container">
    <div class="screen btnBox clearfix">
      <div class="button inquire" @click="editOrganization">添加机构</div>
    </div>
    <div class="screen clearfix">
      <div class="item">
        <span>组织机构名称</span>
        <div class="box">
          <el-input v-model="screen.name" placeholder="输入组织机构名称" style="width: 180px"></el-input>
        </div>
      </div>
      <div class="item">
        <span>组织机构类型</span>
        <div class="box">
          <el-select v-model="screen.type" filterable :popper-append-to-body="false" placeholder="全部"
                     style="width: 180px">
            <el-option
                v-for="item in dictData.type"
                :key="item.id"
                :label="item.value"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item">
        <span>所在地区</span>
        <div class="box">
          <el-cascader
              placeholder="请选择组织机构所在地区" style="width: 180px"
              v-model="addressData"
              :options="dictData.addressData"
              :props="{ label: 'name', value: 'id', multiple: true }"
              collapse-tags
              ref="cascader"
              @change="handleChange"></el-cascader>
        </div>
      </div>
      <div class="item">
        <span>网格属性</span>
        <div class="box">
          <el-select v-model="screen.grid_attr" filterable :popper-append-to-body="false" placeholder="全部"
                     style="width: 180px">
            <el-option
                v-for="item in dictData.grid_attr"
                :key="item.id"
                :label="item.value"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item">
        <span>是否驻地网</span>
        <div class="box">
          <el-select v-model="screen.cpn" filterable :popper-append-to-body="false" placeholder="全部"
                     style="width: 180px">
            <el-option
                v-for="item in dictData.cpn"
                :key="item.id"
                :label="item.value"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <span class="button inquire" @click="search">查询</span>
      <span class="button" @click="resetQuery">重置</span>
    </div>
    <el-table
        ref="multipleTable"
        :data="tableData.data"
        :border="true"
        v-loading="tableData.loading"
        style="width: 100%"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          width="60"
          align="center">
      </el-table-column>
      <el-table-column
          prop="name"
          label="组织机构名称"
          min-width="100%">
      </el-table-column>
      <el-table-column
          prop="type_text"
          label="组织机构类型"
      >
      </el-table-column>
      <el-table-column
          prop="city"
          label="所属州市"
      >
      </el-table-column>
      <el-table-column
          prop="county"
          label="所属区县"
      >
      </el-table-column>
      <el-table-column
          prop="grid_attr"
          label="网格属性"
      >
        <template slot-scope="{row}">
          {{ row.grid_attr === 0 ? '' : selectDictLabel(dictData.grid_attr, row.grid_attr, dictDefaultProps) }}
        </template>
      </el-table-column>
      <el-table-column
          prop="cpn"
          label="是否驻地网"
          align="center"
          width="100px"
      >
        <template slot-scope="{row}">
          <span :class="[row.cpn === 1 ? 'text_success' : (row.cpn === 2 ? 'text_error' : null)]">{{ selectDictLabel(dictData.cpn, row.cpn, dictDefaultProps) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="添加时间">
      </el-table-column>
      <el-table-column
          label="操作"
          width="180">
        <template slot-scope="scope">
          <!-- <router-link :to="{path: '/suervey', praram: 'scope.row.id'}" target="_blank"><span class="handle">查看答卷</span></router-link> -->
          <span class="handle" @click="editOrganization(scope.row)">编辑</span>
          <span class="handle" @click="deleteData(scope.row.id)">删除</span>
          <span class="handle" @click="openDataReport(scope.row)">数据填报</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="operate">
      <div class="handle">
        <div class="all-select">
          <el-checkbox :indeterminate="tableData.isIndeterminate" v-model="tableData.allSelect"
                       @change="toggleSelection()"></el-checkbox>
        </div>
        <div class="button" @click="deleteData()">批量删除</div>
      </div>
      <el-pagination
          :key="screen.page"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="screen.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="screen.limit"
          layout="prev, pager, next, sizes"
          :total="tableData.total">
      </el-pagination>
    </div>

    <filling-method ref="fillingMethod"></filling-method>
  </div>
</template>

<script>
import fillingMethod from "@/views/system/organization/dataReporting/fillingMethod.vue";
const defaultQuery = {
  page: 1, // 请求页码
  limit: 10, // 每页显示条数
  name: '',
  type: null,
  grid_attr: null,
  cpn: null,
  area_id: null,
  level: null,

}
export default {
  name: 'Organization',
  components: {
    fillingMethod
  },
  data() {
    return {
      dictData: {
        // 类型：0=全部 1=省级 2=州市级 3=网格
        type: [
          {
            id: 0,
            value: '全部'
          },
          {
            id: 1,
            value: '省级'
          },
          {
            id: 2,
            value: '州市级'
          },
          {
            id: 3,
            value: '网格'
          }
        ],
        // 网格属性：0=全部 1=园区 2=楼宇 3=行业
        grid_attr: [
          {
            id: 0,
            value: '全部'
          },
          {
            id: 1,
            value: '园区'
          },
          {
            id: 2,
            value: '楼宇'
          },
          {
            id: 3,
            value: '行业'
          }
        ],
        // 是否驻地网：0=全部 1=是 2=否
        cpn: [
          {
            id: 0,
            value: '全部'
          },
          {
            id: 1,
            value: '是'
          },
          {
            id: 2,
            value: '否'
          }
        ],
        // 等级：1=省 2=市 3=区 4=镇
        level: [
          {
            id: 1,
            value: '省'
          },
          {
            id: 2,
            value: '市'
          },
          {
            id: 3,
            value: '市'
          },
          {
            id: 4,
            value: '镇'
          }
        ],
        // 地区
        addressData: []
      },
      screen: {
        ...defaultQuery
      }, // 筛选条件
      addressData: [],
      tableData: {
        data: [], // 表格列表数据
        isIndeterminate: false, // 表格是否有选中但未全选
        allSelect: false, // 表格全选
        multipleSelection: [], // 表格选中列表
        currentPage: 1, // 表格当前页码
        total: 0, // 总条数
        loading: false, // 表格渲染loading
        ids: [], // 表格选中列表id值
      }, // 表格数据
      dictDefaultProps: {
        label: "value",
        value: 'id'
      },
    }
  },
  mounted() {
    console.log(this.$route, 'params')
    this.screen.page = this.$route.params.page || 1;

    this.getDataList()
    //地区
    this.$api.area_tree_list().then(res=>{
      this.$set(this.dictData, 'addressData', res.data)
    })
  },
  methods: {
    handleSelectionChange(val) {
      this.tableData.multipleSelection = val;
      this.tableData.ids = val.map(item => item.id);
      this.tableData.isIndeterminate = val.length > 0 && val.length < this.tableData.data.length;
      this.tableData.allSelect = val.length === this.tableData.data.length ? true : false;
    }, // 表格选中改变
    toggleSelection() {
      this.$refs.multipleTable.toggleAllSelection();
    }, // 表格全选
    handleSizeChange(val) {
      this.screen.limit = val;
      this.getDataList();
    }, // 分页pageSize改变
    handleCurrentChange(val) {
      this.screen.page = val;
      this.getDataList();
    }, // 分页currentPage改变
    /**
     * 查询
     */
    search() {
      this.screen.page = 1;
      this.getDataList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.screen = {
        ...defaultQuery
      }
      this.addressData = []
      this.search();
    },
    /**
     * 获取字典列表数据
     */
    getDataList() {
      this.tableData.loading = true;
      this.$api.organizeList(this.screen).then(res => {
        this.$set(this.tableData, 'data', res.data.data);
        this.$set(this.tableData, 'total', res.data.count);
      }).finally(() => {
        this.tableData.loading = false;
      })
    },
    /**
     * 列表数据删除
     */
    deleteData(id) {
      if (!id && this.tableData.ids.length === 0) {
        this.$message({
          type: 'info',
          message: '请选择需要删除的数据！'
        });
        return false;
      }
      let ids = id ? id : this.tableData.ids;

      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.delOrganize({id: ids}).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.resetQuery()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    editOrganization(row) {
      let id = row?.id
      let path = id || id === 0 ? '/editOrganization' : '/addOrganization';
      this.$router.push({path, query: {id: id, page: this.screen.page}})
    },
    handleChange(value) { //选择地区之后获取网格-
      // this.screen.area_id = value.map(item => item[item.length - 1]);
      this.screen.area_id = JSON.stringify(value);
    },
    openDataReport(row) {
      this.$refs.fillingMethod.showModal(row);
    }
  }
}
</script>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style lang="scss" scoped>
.container {
  margin: 18px;
  padding: 30px 20px;
  width: calc(100% - 76px);
  height: calc(100% - 96px);
  background: #FFFFFF;
  overflow: scroll;
  scrollbar-width: none;


}
</style>
