<script>
import {dataReportingCopy, dataReportingDetail, dataReportingList} from "../../../../request/system";

export default {
  name: "fillingMethod",
  data() {
    return {
      show: false,
      title: ["选择填报方式", "选择需要复制的页面", "选择数据来源的组织机构"],
      active: 0,
      stepIndex: 0,
      row: {},
      dict: {
        decision: [
          {label: "园区总览", value: "1"},
          {label: "政务办公", value: "2"},
          {label: "项目管理", value: "3"},
          {label: "招商管理", value: "4"}
        ],
        service: [
          {label: "园区大数据", value: "1"},
          {label: "企业画像", value: "2"},
          {label: "园区画像", value: "3"},
          {label: "产业画像", value: "4"}
        ],
        org: []
      },
      form: {
        decision: [],
        service: [],
        org: ""
      },
      checkAll: {
        decision: false,
        service: false,
      },
      isIndeterminate: {
        decision: false,
        service: false,
      }
    };
  },
  methods: {
    openDialog() {

    },
    showModal(row) {
      this.show = true;
      this.row = row;
      console.log(row, "row");
    },
    handleClose() {
      this.show = false;

      this.form = {
        decision: [],
        service: [],
        org: ""
      }

      this.active = 0;
      this.stepIndex = 0;

      this.checkAll = {
        decision: false,
        service: false,
      }
      this.isIndeterminate = {
        decision: false,
        service: false,
      }
    },
    handleSubmit(active) {
      if (active === 0 || this.stepIndex === 2) {
        if (this.stepIndex === 2) {
          if (this.form.org === "") {
            this.$message.error("请选择数据来源的组织机构");
            return;
          }
          const params = {
            policy_ids: this.form.decision.join(","),
            service_ids: this.form.service.join(","),
            organize_id: this.row.id,
            copy_organize_id: this.form.org
          }
          this.$api.dataReportingCopy(params).then(res => {
            this.show = false;
            this.$router.push({
              path: '/dataReporting',
              query: {id: this.row.id, fillingMethod: JSON.stringify(this.form)}
            });
          });
          return;
        }
        this.show = false;
        this.$router.push({path: '/dataReporting', query: {id: this.row.id}});
      } else {
        if (this.stepIndex >= 0 && this.stepIndex !== 2) {
          this.stepIndex = this.stepIndex + 1;

          const params = {
            policy_ids: this.form.decision.join(","),
            service_ids: this.form.service.join(","),
          }
          this.$api.dataReportingList(params).then(res => {
            this.dict.org = res.data
          })
        }
      }
      // this.$emit("submit", this.row);
    },
    handleCheckAllDecisionChange(val) {
      this.form.decision = val ? this.dict.decision.map(item => item.value) : [];
      this.isIndeterminate.decision = false;
    },
    handleCheckAllServiceChange(val) {
      this.form.service = val ? this.dict.service.map(item => item.value) : [];
      this.isIndeterminate.service = false;
    },
    handleCheckedDecisionChange(value) {
      let checkedCount = value.length;
      this.checkAll.decision = checkedCount === this.dict.decision.length;
      this.isIndeterminate.decision = checkedCount > 0 && checkedCount < this.dict.decision.length;
    },
    handleCheckedServiceChange(value) {
      let checkedCount = value.length;
      this.checkAll.service = checkedCount === this.dict.service.length;
      this.isIndeterminate.service = checkedCount > 0 && checkedCount < this.dict.service.length;
    }
  }
}
</script>

<template>
  <el-dialog class="dataReporting-dialog" :title="title[stepIndex]" :visible.sync="show" width="490px" :before-close="handleClose"
             @open="openDialog">
    <div class="step step1" v-show="stepIndex === 0">
      <div class="content btnOption-box mb-46">
        <div class="btnOption btn1 pointer border-box" :class="{active: active === 0}" @click="active = 0">
          <div><i class="fill-in"></i>自主填报</div>
        </div>
        <div class="btnOption btn2 pointer border-box" :class="{active: active === 1}" @click="active = 1">
          <div><i class="copy"></i>从已有模拟里复制</div>
        </div>
      </div>
      <div class="btn_box">
        <span class="" @click="handleClose">取消</span>
        <span class="blue" @click="handleSubmit(active)">{{ active === 0 ? '确定' : '下一步' }}</span>
      </div>
    </div>
    <div class="step step2" v-show="stepIndex === 1">
      <div class="content">
        <el-checkbox :indeterminate="isIndeterminate.decision" v-model="checkAll.decision"
                     @change="handleCheckAllDecisionChange">园区决策平台
        </el-checkbox>
        <div class="mb-10"></div>
        <el-checkbox-group v-model="form.decision" @change="handleCheckedDecisionChange">
          <el-checkbox v-for="(item, index) in dict.decision" :label="item.value" :key="index">{{
              item.label
            }}
          </el-checkbox>
        </el-checkbox-group>
        <div class="mb-20"></div>
        <el-checkbox :indeterminate="isIndeterminate.service" v-model="checkAll.service"
                     @change="handleCheckAllServiceChange">园区服务平台
        </el-checkbox>
        <div class="mb-10"></div>
        <el-checkbox-group v-model="form.service" @change="handleCheckedServiceChange">
          <el-checkbox v-for="(item, index) in dict.service" :label="item.value" :key="index">{{
              item.label
            }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="btn_box">
        <span class="" @click="stepIndex--">上一步</span>
        <span class="blue" @click="handleSubmit(active)">{{ active === 0 ? '确定' : '下一步' }}</span>
      </div>
    </div>
    <div class="step step3" v-show="stepIndex === 2">
      <div class="content">
        <div class="label">请选择数据来源的组织机构，系统将从该机构里复制所选页面的数据到当前机构</div>
        <div class="text">
          <el-select v-model="form.org" placeholder="请选择" style="width: 340px">
            <el-option
                v-for="item in dict.org"
                :key="item.organize_id"
                :label="item.organize_name"
                :value="item.organize_id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="btn_box">
        <span class="" @click="stepIndex--">上一步</span>
        <span class="blue" @click="handleSubmit(active)">确定</span>
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
::v-deep.dataReporting-dialog {

  .el-dialog {
    margin-top: 25% !important;
    transform: translateY(-50%) !important;
  }
}

.btn_box {
  text-align: center;
}

.btnOption-box {
  text-align: center;

  .btnOption {
    display: flex;
    width: 320px;
    height: 50px;
    line-height: 50px;
    border-radius: 6px;
    background-color: #F1F4F7;
    color: #A7B2B8;
    font-size: 14px;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;

    > div {
      display: flex;
      align-items: center;
      width: 160px;
    }

    &:hover {
      background: darken(#F1F4F7, 5%);
      color: darken(#A7B2B8, 5%);
    }

    &.active {
      background-color: #3984F5;
      color: #fff;

      &:hover {
        background: lighten(#3984F5, 5%);
        color: lighten(#fff, 5%);
      }

      .fill-in {
        background-image: url(~@/assets/org/fill_in_blue_icon@2x.png);
      }

      .copy {
        background-image: url(~@/assets/org/copy_blue_icon@2x.png);
      }
    }

    .btnOptionIcon {
      box-sizing: border-box;
      display: inline-block;
      width: 36px;
      height: 36px;
      margin-right: 10px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
    }

    .fill-in {
      @extend .btnOptionIcon;
      background-image: url(~@/assets/org/fill_in_grey_icon@2x.png);
    }

    .copy {
      @extend .btnOptionIcon;
      background-image: url(~@/assets/org/copy_grey_icon@2x.png);
    }
  }
}

.step3 {
  .label {
    font-weight: bold;
    font-size: 12px;
    color: #242D33;
    line-height: 36px;
  }
}
</style>